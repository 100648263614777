<template>
  <section class="gamelist">
    <h2><img src="@/assets/img/money.svg">HOTEL CASINO <em>GAME LIST</em></h2>
    <ul class="betGames betGamesa">
      <template v-for="item in commonCodeByOrder['casino']" v-bind:key="item.vendorKey">
        <li
            v-if="item.groupCodeName.indexOf('HC-') > -1"
            @click="onCasinoSelectGame(item.groupCode, item.code)"
        >
          <div class="front" :style="getBackgroundImage(item)">
             <!-- <em class="glogo" :style="getBackgroundLogoImage(item)"></em> -->
             <p class="name">
               {{$i18n.locale !== 'ko' ? item[`codeName${$i18n.locale.toUpperCase()}`] : item.codeName}}
               <em :class="item.groupCode">{{ $t('front.gnb.livecasino') }}</em>
             </p>
          </div>
        </li>
      </template>
    </ul>
  </section>
</template>

<script>
import '@/styles/common.css'
import '@/styles/main.css'
import { mapState } from 'vuex'

export default {
  name: 'GameCasino',
  components: {
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapState([
      'commonCodeByOrder'
    ])
  },
  methods: {
    getBackgroundImage (item) {
      try {
        const image = require('../../../assets/img/gamecardNew/game_main_pc_' + item.code + '_off.png')
        const imageOff = require('../../../assets/img/gamecardNew/game_main_pc_' + item.code + '_off.png')
        if (image && imageOff) {
          if (item.isHover) {
            return { backgroundImage: 'url(' + image + ')' }
          } else {
            return { backgroundImage: 'url(' + imageOff + ')' }
          }
        } else {
          return { backgroundImage: 'url()' }
        }
      } catch (e) {
        return { backgroundImage: 'url()' }
      }
    },
    getBackgroundLogoImage (item) {
      try {
        const image = require('../../../assets/img/glogo' + item.code + '.png')
        return { backgroundImage: 'url(' + image + ')' }
      } catch (e) {
        return { backgroundImage: 'url()' }
      }
    }
  }
}
</script>
<style scoped src="@/styles/main.css"></style>
<style scoped src="@/styles/gamelist.css"></style>
<style scoped>
</style>
